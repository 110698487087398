<template>
  <div class="myorder">
    <div class="open_box" v-if="open">
      <div class="year_box">
        <van-datetime-picker v-model="currentDate" type="date" title="选择年月" :min-date="minDate" :max-date="maxDate"
          :formatter="formatter" @cancel="close" @confirm="confirm" />
      </div>
    </div>
    <van-action-sheet v-model="show" :actions="actions" cancel-text="取消" close-on-click-action @cancel="onCancel"
      @select="select" />
    <div class="order_box">
      <div class="nav-box">
        <div @click="changeSearchType(1)" class="navItem" :class="navIndex==1?'navItemActive':''" style="margin-right: 20px;">充电宝订单</div>
        <div @click="changeSearchType(2)" class="navItem" :class="navIndex==2?'navItemActive':''" style="margin-right: 20px;">充电线订单</div>
        <div @click="changeSearchType(3)" class="navItem" :class="navIndex==3?'navItemActive':''">充电桩订单</div>

      </div>

      <div class="change_find">
        <div class="change_time" v-if="!isAdvance">
          <span @click="openyear(true)">{{ beginTime }}</span><span class="span">至</span><span
            @click="openyear(false)">{{ endTime }}</span>
        </div>
        <div class="change_type">
          <div @click="openselect">{{ ordertitle }}</div>
          <div class="change_img" v-if="!open"></div>
          <div class="change_img2" v-if="open"></div>
        </div>
      </div>
      <div class="order_input">
        <img class="search_ico" src="../assets/img/search.png" />
        <input class="inputbox" placeholder="搜索订单" v-model="quarmas.orderSn" @blur="init" />
      </div>
      <div class="order_num">订单数：{{ total }}</div>
      <!-- 非预分成商户 Begin -->
      <div class="order_detail" v-for="(item, index) in tabledata" :key="index" v-if="!isAdvance">
        <div class="detail_left">
          <div class="ms_box">订单号：{{ item.orderSn }}</div>
          <div class="ms_box">商户：{{ item.rentMerchantName }}</div>
          <div class="ms_box" v-if="item.rentTime">
            租借时间：{{
            item.rentTime.split("T")[0] +
            " " +
            item.rentTime.split("T")[1].split(".")[0]
            }}
          </div>
          <div class="ms_box">租借门店：{{ item.rentAddress }}</div>
          <div class="ms_box" v-if="item.returnStatus == 2 && item.returnTime">
            归还时间：{{
            item.returnTime.split("T")[0] +
            " " +
            item.returnTime.split("T")[1].split(".")[0]
            }}
          </div>
          <div class="ms_box">
            订单金额：{{ item.orderPrice ? item.orderPrice : 0 }}元
          </div>
        </div>
        <div class="detail_right">
          <div class="jg_right">¥{{ item.income ? item.income : 0 }}</div>
          <div class="ms_right">我的收益</div>
        </div>
      </div>
      <!-- 非预分成商户 End -->

      <!-- 预分成商户 Begin -->
      <div class="order_detail" v-for="(item, index) in tabledata" :key="index" v-if="isAdvance">
        <div class="detail_left">
          <div class="ms_box">订单号：{{ item.orderSn }}</div>
          <div class="ms_box">商户：{{ item.rentMerchantName }}</div>
          <div class="ms_box">租借门店：{{ item.rentAddress }}</div>
          <div class="ms_box" v-if="item.returnStatus == 2 && item.returnTime">
            归还时间：{{
            item.returnTime.split("T")[0] +
            " " +
            item.returnTime.split("T")[1].split(".")[0]
            }}
          </div>
        </div>
        <div class="detail_right">
          <div class="jg_right">¥{{ item.orderPrice ? item.orderPrice : 0 }}</div>
          <div class="ms_right">订单金额</div>
        </div>
      </div>
      <!-- 预分成商户 End -->
      <div class="order_more" @click="addpage" v-if="total >= (quarmas.pageNum + 1) * quarmas.pageSize">
        加载更多...
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      open: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      show: false,
      actions: [
        { name: "全部订单", type: "" },
        { name: "租借中", type: 1 },
        { name: "已完成", type: 2 },
      ],
      ordertitle: "全部订单",
      dealStatus:"",
      quarmas: {
        pageNum: 0,
        pageSize: 10,
        beginTime: "",
        endTime: "",
        orderSn: "",
        searchType:1
      },
      beginTime: "开始时间",
      endTime: "结束时间",
      time: true,
      total: 0,
      tabledata: [],
      copy: false,
      // 是否为预分成商户
      isAdvance: false,
      navIndex:1
    };
  },
  created() {},
  mounted() {
    this.init();
    this.getDetails();
  },
  methods: {
    addpage() {
      this.copy = true;
      this.quarmas.pageNum = this.quarmas.pageNum + 1;
      this.init();
    },
    init() {
      var params = {...this.quarmas}
      if (params.searchType == 1){
        params.dealStatus = this.dealStatus
      }else{
        params.status = this.dealStatus
      }
      this.$http.orderlist(params).then((res) => {
        if (res.code == 200) {
          console.log(res);
          this.total = res.total;
          if (this.copy) {
            this.tabledata = [...this.tabledata, ...res.orders];
            this.copy = false;
          } else {
            this.tabledata = res.orders;
          }
        }
      });
    },
    changeSearchType(index){
      this.navIndex = index
      this.quarmas.pageNum = 0;
      this.quarmas.searchType = index
      this.init();
      

    },
    onCancel() {},
    openselect() {
      this.show = true;
    },
    select(action, index) {
      console.log(action.type, index);
      this.ordertitle = action.name;
      this.dealStatus = action.type;
      this.init();
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      }
      return val;
    },
    openyear(val) {
      this.time = val;
      this.open = true;
    },
    close() {
      this.open = false;
    },
    confirm(value) {
      console.log(value);
      let month = value.getMonth() + 1;
      if (month < 10) {
        month = "0" + (value.getMonth() + 1);
      }
      let day = value.getDate();
      if (day < 10) {
        day = "0" + value.getDate();
      }
      let year = value.getFullYear() + "-" + month + "-" + day;
      console.log(this.time);
      if (this.time) {
        this.beginTime = year;
        this.quarmas.beginTime = year + " " + "00:00:00";
      } else {
        console.log("进来了");
        this.endTime = year;
        this.quarmas.endTime = year + " " + "00:00:00";
      }
      console.log(this.quarmas);
      this.open = false;
      this.init();
    },
    /**
     * 获取当前登录商户数据
     */
    getDetails () {
      this.$http.getDetails(null).then((res) => {
        if (res.code == 200) {
          this.isAdvance = res.merchant.isAdvance;
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.myorder {
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  overflow-y: auto;
  .open_box {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.65);
    z-index: 999;
    display: flex;
    .year_box {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      background: forestgreen;
    }
  }
  .order_box {
    margin: 0 auto;
    margin-top: 24px;
    width: 335px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .nav-box{
      margin-top: 10px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 700;
      .navItemActive{
        color: #1890ff;
        border-bottom: 2px solid #1890ff;
        
      }


    }
    .change_find {
      width: 100%;
      height: 48px;
      padding: 0 16px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eee;
      .change_time {
        font-weight: normal;
        color: #1890ff;
        font-size: 14px;
        .span {
          color: #333333;
          padding: 0 3px;
        }
      }
      .change_type {
        display: flex;
        align-items: center;
        color: #333333;
        font-size: 14px;
        font-weight: normal;
        .change_img {
          margin-left: 4px;
          width: 0;
          height: 0;
          border-right: 5px solid transparent;
          border-left: 5px solid transparent;
          border-top: 5px solid #ccc;
        }
        .change_img2 {
          margin-left: 4px;
          width: 0;
          height: 0;
          border-right: 5px solid transparent;
          border-left: 5px solid transparent;
          border-top: 5px solid #1890ff;
        }
      }
    }
    .order_input {
      width: 303px;
      height: 36px;
      background: #f6f6f6;
      border-radius: 20px;
      margin: 0 auto;
      margin-top: 15px;
      position: relative;
      .search_ico {
        position: absolute;
        width: 17px;
        height: 18px;
        top: 9px;
        left: 16px;
      }
      .inputbox {
        width: 303px;
        height: 36px;
        background: #f6f6f6;
        border-radius: 20px;
        margin: 0 auto;
        padding-left: 38px;
        box-sizing: border-box;
        font-size: 12px;
      }
    }
    .order_num {
      width: 100%;
      padding: 16px;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
    }
    .order_detail {
      width: 303px;
      margin: 0 auto;
      padding-top: 15px;
      padding-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #eeeeee;
      .detail_left {
        width: 210px;
        height: 100%;
        .ms_box {
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          margin-bottom: 8px;
        }
      }
      .detail_right {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .jg_right {
          font-weight: 600;
          color: #1890ff;
          font-size: 20px;
        }
        .ms_right {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
        }
      }

    }
    .order_more {
      width: 100%;
      height: 50px;
      font-size: 16px;
      text-align: center;
      border-top: 1px solid #eeeeee;
      line-height: 50px;
    }
  }
}

</style>